import { DeletableModel, LabelledUser } from 'shared/domain/commonModel';
import { CompanyModel } from 'shared/domain/company/types/model';
import { ContractModel } from 'shared/domain/contract/types/model';
import {
  EventDescriptionPartModel,
  IssueEventModel,
  IssueLocationType,
  IssueModel,
  MentionModel,
  TextDescriptionModel,
} from 'shared/domain/issue/issueModel';
import { LevelModel } from 'shared/domain/level/types/model';
import { SiteModel } from 'shared/domain/site/types/model';
import { UserModel } from 'shared/domain/user/userModel';
import { generateNcrNumber } from 'helpers/generators';
import { toLabelledEntity, toUnsafeLabelledEntity } from 'helpers/misc';
import { DateTime } from 'luxon';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  CreatableOnView,
  HashMap,
  Identificable,
  LabelledEntity,
  ModifiableOnView,
} from 'shared/types/commonView';
import { FieldSkeleton, ProcessType } from 'shared/types/form';
import { StageInternalNames } from 'shared/types/stages';
import { UserAccess } from 'shared/types/userAccess';
import { LegacyIssueForm } from 'views/issue/issueView/UnifiedIssueViewContainer/types';
import {
  EventDescriptionPartOnView,
  Mention,
  SelfMention,
  TextDescription,
} from './eventDescription';
import { EventDescriptionType } from 'shared/types/eventDescription';
import { MapCoordinates } from 'shared/types/mapCoordinates';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { toViewDate } from 'shared/utils/date/toViewDate';

type EstimatedCost = {
  cost: string;
  coveredBy: LabelledEntity | undefined;
};

type FinalCost = {
  outstanding: string;
  settled: string;
  coveredBy: LabelledEntity | undefined;
};

export type IssueEventOnView = {
  title: string;
  type: string;
  description: EventDescriptionPartOnView[];
  deleted: boolean;
  loading?: boolean;
} & Identificable &
  CreatableOnView &
  ModifiableOnView;

type Point = { x: number; y: number };
type Area = Point[];
export type IssueOnSingleView = {
  _id: string;
  primaryData: {
    site: LabelledEntity;
    title: string;
    assignee: LabelledUser | undefined;
    description: string;
    detectionDate: DateTime | undefined;
    subcontractors: LabelledEntity[];
    contractNumbers: LabelledEntity[];
    level: LabelledEntity;
    positionOnMap: MapCoordinates;
    executor: LabelledUser | undefined;
    selectedLocationType: IssueLocationType;
    targetAreas: Area[];
    finalAreas: Area[];
  };
  extendedData: {
    workTypes: LabelledEntity[] | undefined;
    rootCauses: LabelledEntity[] | undefined;
    estimatedCost: EstimatedCost[] | undefined;
    finalCost: FinalCost[] | undefined;
    targetCompletionDate: DateTime | undefined;
    finalCompletionDate: DateTime | undefined;
    solutionProposal: string | undefined;
    impact: LabelledEntity | undefined;
    environmentalAspect: LabelledEntity | undefined;
    effect: LabelledEntity | undefined;
    hazardCategory: LabelledEntity[] | undefined;
    expectedFine: number | undefined;
    imposedFine: number | undefined;
    solutionMethod: string | undefined;
    subcontractorRepresentative: string | undefined;
    decisionToImposeFine: LabelledEntity | undefined;
    circumstances: LabelledEntity | undefined;
    proposedCorrectiveAction: LabelledEntity | undefined;
    costCode: string | undefined;
    daysOfInabilityToWork: number | undefined;
    personUnableToWork: string | undefined;
    spilledSubstance: LabelledEntity | undefined;
    waterLeakageScale: number | undefined;
    soilLeakageScale: number | undefined;
    contaminatedSoilScale: number | undefined;
    targetStartDate: DateTime | undefined;
    finalStartDate: DateTime | undefined;
    completionDateDelay: string | undefined;
    startDateDelay: string | undefined;
    executedByCompanies: LabelledEntity[] | undefined;
    targetAmount: number | undefined;
    completedAmount: number | undefined;
    amountPercentage: number | undefined;
    numberOfEmployees: number | undefined;
  };
  inspection: string | undefined;
  protocolItem: string | undefined;
  process: LabelledEntity;
  stage: StageInternalNames;
  userAccesses: UserAccess[];
  hashtag: string;
  events: IssueEventOnView[];
  ncrNumber: string;
  mainImage: string | undefined;
} & CreatableOnView &
  ModifiableOnView &
  DeletableModel;

function toEventDescriptionPartOnView(
  description: EventDescriptionPartModel,
  currentUserId: string,
  users: UserModel[]
): EventDescriptionPartOnView | null {
  switch (description.type) {
    case EventDescriptionType.text:
      return new TextDescription(description as TextDescriptionModel);
    case EventDescriptionType.mention:
      const mention = description as MentionModel;
      const user = users.find(
        (userModel) => userModel._id === mention.user
      );
      if (!user) {
        return null;
      }
      return mention.user === currentUserId
        ? new SelfMention({ type: mention.type, user })
        : new Mention({ type: mention.type, user });
  }
}

function toIssueEventOnView(
  event: IssueEventModel,
  currentUserId: string,
  users: UserModel[]
): IssueEventOnView {
  return {
    _id: event._id,
    title: event.title,
    type: event.type,
    description: event.description.reduce<EventDescriptionPartOnView[]>(
      (descriptionPartsOnView, partModel) => {
        const part = toEventDescriptionPartOnView(
          partModel,
          currentUserId,
          users
        );
        if (part !== null) {
          descriptionPartsOnView.push(part);
        }

        return descriptionPartsOnView;
      },
      []
    ),
    deleted: event.deleted,
    loading: false,
    modifiedAt: stringToViewDate(event.modifiedAt),
    modifiedBy: event.modifiedBy,
    createdAt: stringToViewDate(event.createdAt),
    createdBy: event.createdBy,
  };
}

export function toIssueOnSingleView(
  issueModel: IssueModel,
  currentUserId: string,
  sites: SiteModel[],
  levels: LevelModel[],
  processes: ProcessType[],
  issueForms: HashMap<LegacyIssueForm>,
  subcontractors: HashMap<CompanyModel>,
  contractNumbers: HashMap<ContractModel>,
  users: UserModel[],
  timezone: string
): IssueOnSingleView {
  const issueProcess = processes.find(
    ({ _id }) => _id === issueModel.process
  );
  const issueSite = sites.find(
    ({ _id }) => _id === issueModel.primaryData.site
  );
  const issueLevel = levels.find(
    ({ _id }) => _id === issueModel.primaryData.level
  );

  // @ts-ignore cannot use undefined as index
  const issueForm: LegacyIssueForm = issueForms[issueProcess?._id] || {
    primaryFields: [],
    extendedFields: [],
  };

  const ncrNumber = generateNcrNumber(
    issueModel.createdAt,
    issueProcess?.code ?? '',
    issueSite?.code ?? '',
    issueModel._id.slice(-4)
  );

  return {
    _id: issueModel._id,
    createdAt: stringToViewDate(issueModel.createdAt),
    createdBy: issueModel.createdBy
      ? {
          _id: issueModel.createdBy._id,
          email: issueModel.createdBy.email,
          label: issueModel.createdBy.label,
        }
      : undefined,
    deleted: issueModel.deleted ? true : false,
    events: !issueModel.events
      ? []
      : issueModel.events?.map((event) =>
          toIssueEventOnView(event, currentUserId, users)
        ),
    extendedData: {
      effect: findValue(
        findField(issueForm, 'extendedFields', 'effect'),
        issueModel.extendedData.effect
      ),
      environmentalAspect: findValue(
        findField(issueForm, 'extendedFields', 'environmentalAspect'),
        issueModel.extendedData.environmentalAspect
      ),
      estimatedCost: issueModel.extendedData.estimatedCost
        ? issueModel.extendedData.estimatedCost?.map((costObject) => {
            const found = subcontractors[costObject.coveredBy as string];
            return {
              cost: costObject.cost,
              coveredBy: found
                ? { _id: found._id, label: found.shortLabel }
                : undefined,
            };
          })
        : undefined,
      expectedFine: issueModel.extendedData.expectedFine,
      finalCompletionDate: toViewDate(
        timezone,
        issueModel.extendedData.finalCompletionDate
      ),
      finalCost: issueModel.extendedData.finalCost
        ? issueModel.extendedData.finalCost?.map((costObject) => {
            const found = subcontractors[costObject.coveredBy as string];
            return {
              outstanding: costObject.outstanding,
              settled: costObject.settled,
              coveredBy: found
                ? { _id: found._id, label: found.shortLabel }
                : undefined,
            };
          })
        : undefined,
      hazardCategory: findValues(
        findField(issueForm, 'extendedFields', 'hazardCategory'),
        issueModel.extendedData.hazardCategory
      ),
      impact: findValue(
        findField(issueForm, 'extendedFields', 'impact'),
        issueModel.extendedData.impact
      ),
      imposedFine: issueModel.extendedData.imposedFine,
      rootCauses: findValues(
        findField(issueForm, 'extendedFields', 'rootCauses'),
        issueModel.extendedData.rootCauses
      ),
      solutionProposal: issueModel.extendedData.solutionProposal,
      targetCompletionDate: toViewDate(
        timezone,
        issueModel.extendedData.targetCompletionDate
      ),
      workTypes: findValues(
        findField(issueForm, 'extendedFields', IssueFieldNames.workTypes),
        issueModel.extendedData.workTypes
      ),
      circumstances: findValue(
        findField(issueForm, 'extendedFields', 'circumstances'),
        issueModel.extendedData.circumstances
      ),
      contaminatedSoilScale: issueModel.extendedData.contaminatedSoilScale,
      costCode: issueModel.extendedData.costCode,
      daysOfInabilityToWork: issueModel.extendedData.daysOfInabilityToWork,
      decisionToImposeFine: findValue(
        findField(issueForm, 'extendedFields', 'decisionToImposeFine'),
        issueModel.extendedData.decisionToImposeFine
      ),
      personUnableToWork: issueModel.extendedData.personUnableToWork,
      proposedCorrectiveAction: findValue(
        findField(issueForm, 'extendedFields', 'proposedCorrectiveAction'),
        issueModel.extendedData.proposedCorrectiveAction
      ),
      soilLeakageScale: issueModel.extendedData.soilLeakageScale,
      solutionMethod: issueModel.extendedData.solutionMethod,
      spilledSubstance: findValue(
        findField(issueForm, 'extendedFields', 'spilledSubstance'),
        issueModel.extendedData.spilledSubstance
      ),
      subcontractorRepresentative:
        issueModel.extendedData.subcontractorRepresentative,
      waterLeakageScale: issueModel.extendedData.waterLeakageScale,
      targetStartDate: toViewDate(
        timezone,
        issueModel.extendedData.targetStartDate
      ),
      finalStartDate: toViewDate(
        timezone,
        issueModel.extendedData.finalStartDate
      ),
      targetAmount: issueModel.extendedData.targetAmount,
      completedAmount: issueModel.extendedData.completedAmount,
      amountPercentage: issueModel.extendedData.amountPercentage,
      executedByCompanies: !issueModel.extendedData.executedByCompanies
        ? []
        : issueModel.extendedData.executedByCompanies
            ?.map((company) => {
              const found = subcontractors[company];
              return found && { _id: found._id, label: found.shortLabel };
            })
            .filter((x) => x),
      startDateDelay: issueModel.extendedData.startDateDelay,
      completionDateDelay: issueModel.extendedData.completionDateDelay,
      numberOfEmployees: issueModel.extendedData.numberOfEmployees,
    },
    hashtag: issueModel.hashtag,
    inspection: issueModel.inspection,
    modifiedAt: stringToViewDate(issueModel.modifiedAt),
    modifiedBy: issueModel.modifiedBy
      ? {
          _id: issueModel.modifiedBy._id,
          email: issueModel.modifiedBy.email,
          label: issueModel.modifiedBy.label,
        }
      : undefined,
    primaryData: {
      site: toLabelledEntity(issueSite!),
      title: issueModel.primaryData.title,
      assignee: issueModel.primaryData.assignee,
      description: issueModel.primaryData.description,
      detectionDate: toViewDate(
        timezone,
        issueModel.primaryData.detectionDate
      ),
      subcontractors: !issueModel.primaryData.subcontractors
        ? []
        : issueModel.primaryData.subcontractors
            ?.map((subcontractor) => {
              const found = subcontractors[subcontractor];
              return found && { _id: found._id, label: found.shortLabel };
            })
            .filter((x) => x),
      contractNumbers: !issueModel.primaryData.contractNumbers
        ? []
        : (issueModel.primaryData.contractNumbers
            ?.map((contract) =>
              toUnsafeLabelledEntity(contractNumbers[contract])
            )
            .filter((x) => x) as LabelledEntity[]),
      level: toLabelledEntity(issueLevel!),
      positionOnMap: issueModel.primaryData.positionOnMap,
      executor: issueModel.primaryData.executor,
      selectedLocationType: issueModel.primaryData.selectedLocationType,
      targetAreas: issueModel.primaryData.targetAreas || [],
      finalAreas: issueModel.primaryData.finalAreas || [],
    },
    process: toLabelledEntity(issueProcess!),
    protocolItem: issueModel.protocolItem,
    stage: issueModel.stage,
    userAccesses: issueModel.userAccesses,
    mainImage: issueModel.mainImage,
    ncrNumber,
  };
}

function findField(
  form: LegacyIssueForm,
  fieldType: 'extendedFields' | 'primaryFields',
  fieldName: string
): FieldSkeleton | undefined {
  return form[fieldType].find((field) => field.name === fieldName);
}

function findValue(
  fieldSkeleton: FieldSkeleton | undefined,
  valueToFind: string | undefined | null
): LabelledEntity | undefined {
  if (!fieldSkeleton || !valueToFind) return undefined;
  return (fieldSkeleton.items || []).find(
    (item) => item._id === valueToFind
  );
}

function findValues(
  fieldSkeleton: FieldSkeleton | undefined,
  valuesToFind: string[] | undefined | null
): LabelledEntity[] {
  if (!fieldSkeleton || !valuesToFind) return [];
  const map =
    (fieldSkeleton.items || []).reduce<HashMap<LabelledEntity>>(
      (result, item) => {
        result[item._id] = item;
        return result;
      },
      {} as HashMap<LabelledEntity>
    ) || {};

  return valuesToFind.map((value) => map[value]).filter((x) => x);
}
