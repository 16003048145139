import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  PropsWithChildren,
  useRef,
} from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import {
  GetAllFilteredIssues,
  IssueObj,
  IssuesContextType,
} from './types';
import { ChannelNames } from 'shared/domain/channelNames';
import { useIssueFilters } from '../../dataProviders/withIssueFilters';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useSites } from '../withSites';
import { standariseFilters } from 'shared/utils/filters';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { StoreState } from 'setup/types/core';
import { useLevels } from '../withLevels';
import { useGenerateForms } from 'components/issue/useGenerateForms';
import { HashMap } from 'shared/types/commonView';
import { useContracts } from '../withContracts';
import { ContractModel } from 'shared/domain/contract/types/model';
import { CompanyModel } from 'shared/domain/company/types/model';
import { activeFiltersSelector } from 'redux/selectors/issues';
import { toIssueOnTableView } from './model';
import { IssueTableColumn } from '../../../views/issueTable/tableView/TableController';
import { usePagination } from '../../common/withPagination/withPagination';
import {
  useIssueCount,
  withIssueCount,
} from '../../dataProviders/withIssueCount';
import { useEmptyListReason } from 'hooks/table/useEmptyListReason';
import { useIssueGetParameters } from 'components/dataProviders/withIssueGetParameters';
import { useCompanies } from '../withCompanies';

const IssuesContext = React.createContext<IssuesContextType | undefined>(
  undefined
);

const _WithIssues: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { setCount, setLoading: setLoadingIssueCount } = useIssueCount();
  const pagination = usePagination();
  const setPage = pagination.setPage;
  const { parametersStore, setParameters } = useIssueGetParameters();

  const [parameters, _setParameters] = useState(parametersStore.get());
  useEffect(() => {
    _setParameters(parametersStore.get());
    return parametersStore.subscribe(() => {
      _setParameters(parametersStore.get());
    });
  }, [parametersStore]);

  useEffect(() => {
    const offset = pagination.page * pagination.rowsPerPage;
    const currentOffset = parametersStore.get().offset;
    if (offset === currentOffset) return;
    setParameters({ offset: pagination.page * pagination.rowsPerPage });
  }, [
    pagination.page,
    pagination.rowsPerPage,
    setParameters,
    parametersStore,
  ]);

  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const {
    levels: { items: levels },
    loading: loadingLevels,
  } = useLevels();

  const {
    contracts: { items: contractsArray },
    loading: loadingContracts,
  } = useContracts();
  const {
    companies: { items: companiesArray },
    loading: loadingCompanies,
  } = useCompanies();

  const contracts = useMemo(() => {
    return contractsArray.reduce<HashMap<ContractModel>>(
      (result, contract) => {
        result[contract._id] = contract;
        return result;
      },
      {}
    );
  }, [contractsArray]);

  const companies = useMemo(() => {
    return companiesArray.reduce<HashMap<CompanyModel>>(
      (result, company) => {
        result[company._id] = company;
        return result;
      },
      {}
    );
  }, [companiesArray]);

  const { processes, timezone } = useSelector(projectDataSelector);
  const currentUserId = useSelector(
    (state: StoreState) => state.user.data._id
  );
  const { filters } = useIssueFilters();
  const activeFilters = useMemo(
    () => activeFiltersSelector(filters),
    [filters]
  );
  const prevDeletedFilterOn = useRef(parameters.deleted);
  const isDeletedFilterOn = parameters.deleted;
  const searchString = parameters.search;

  const [issues, setIssues] = useState<IssueObj>({ items: [], total: 0 });

  const {
    forms,
    loading: loadingForms,
    loadingStore: loadingFormsStore,
  } = useGenerateForms();

  const issuesOnView = useMemo(() => {
    return {
      items: loadingForms
        ? []
        : issues.items.map((issue: IssueModel) =>
            toIssueOnTableView(
              issue,
              currentUserId,
              sites,
              levels,
              processes,
              forms,
              companies,
              contracts,
              timezone
            )
          ),
      total: issues.total,
    };
  }, [
    issues.items,
    issues.total,
    currentUserId,
    sites,
    levels,
    processes,
    forms,
    companies,
    contracts,
    loadingForms,
  ]);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDeleted, setLoadingDeleted] = useState<boolean>(true);
  const [columns, setColumns] = useState<IssueTableColumn[]>([]);

  useEffect(() => {
    if (prevDeletedFilterOn.current !== isDeletedFilterOn) {
      setPage(0);
      prevDeletedFilterOn.current = isDeletedFilterOn;
    }
  }, [isDeletedFilterOn, setPage]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
    let resynced = false;

    const searchFilter = searchString
      ? [{ name: 'search', value: searchString }]
      : [];

    const sort = parameters.sort;
    const filterSet = standariseFilters(activeFilters);
    const searchSet = standariseFilters(searchFilter);
    const paginationSet = {
      page: pagination.page,
      size: pagination.rowsPerPage,
    };
    //used to identify call
    const id = JSON.stringify({
      filterSet,
      searchSet,
      sort,
      paginationSet,
    });

    async function sendGetFiltered(): Promise<void> {
      if (sort && !broadcast.isClosed) {
        broadcast.postMessage({
          type: DomainMessagesTypes.getFiltered,
          data: {
            filters: filterSet,
            search: searchSet,
            pagination: paginationSet,
            sort,
            archived: parameters.deleted,
            timezone,
          },
          uniqueId: id,
        });
      }
    }

    broadcast.onmessage = (event: Message): void => {
      if (
        event.type === DomainMessagesTypes.filteredIssues &&
        event.data &&
        event.uniqueId === id
      ) {
        setIssues(event.data);
        setLoading(false);
        setLoadingDeleted(false);
      }

      // BUGFIX PT-4087
      if (event.type === DomainMessagesTypes.allIssues && !resynced) {
        resynced = true;
        sendGetFiltered();
      }
    };

    sendGetFiltered();
    setLoading(true);
    setLoadingDeleted(true);

    return (): void => {
      broadcast.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    searchString,
    columns,
    parameters,
    pagination.page,
    pagination.rowsPerPage,
  ]);

  useEffect(() => {
    let theColumns: any;
    setColumns((prev: any) => {
      theColumns = prev;
      return prev;
    });
  }, []);

  const emptyListReason = useEmptyListReason(
    searchString,
    issues.total,
    activeFilters.length
  );

  const getAllFilteredIssues: GetAllFilteredIssues = useCallback(() => {
    const broadcast = new BroadcastChannel(ChannelNames.issueChannel);

    const sort = parameters.sort;
    const searchFilter = searchString
      ? [{ name: 'search', value: searchString }]
      : [];

    const filterSet = standariseFilters(activeFilters);
    const searchSet = standariseFilters(searchFilter);
    const paginationSet = {
      // size 0 or page undefined causes all data to be returned
      page: undefined,
      size: 0,
    };
    //used to identify call
    const id = JSON.stringify({
      filterSet,
      searchSet,
      sort,
      paginationSet,
    });

    async function sendGetFiltered(): Promise<void> {
      if (sort && !broadcast.isClosed) {
        broadcast.postMessage({
          type: DomainMessagesTypes.getFiltered,
          data: {
            filters: filterSet,
            search: searchSet,
            pagination: paginationSet,
            sort,
            archived: parameters.deleted,
            timezone,
          },
          uniqueId: id,
        });
      }
    }

    return new Promise((resolve, reject) => {
      const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
        broadcast.close();
        reject();
        // 60 seconds to resolve or timeout reject
      }, 60000);

      broadcast.onmessage = (event: Message): void => {
        if (
          event.type === DomainMessagesTypes.filteredIssues &&
          event.data &&
          event.uniqueId === id
        ) {
          broadcast.close();
          clearTimeout(timeout);
          if (loadingFormsStore.get()) {
            const unsubscribe = loadingFormsStore.subscribe(() => {
              unsubscribe();
              resolve({
                items: event.data.items.map((issue: IssueModel) =>
                  toIssueOnTableView(
                    issue,
                    currentUserId,
                    sites,
                    levels,
                    processes,
                    forms,
                    companies,
                    contracts,
                    timezone
                  )
                ),
                total: event.data.items.length,
              });
            });
          } else {
            resolve({
              items: event.data.items.map((issue: IssueModel) =>
                toIssueOnTableView(
                  issue,
                  currentUserId,
                  sites,
                  levels,
                  processes,
                  forms,
                  companies,
                  contracts,
                  timezone
                )
              ),
              total: event.data.items.length,
            });
          }
        }
      };

      sendGetFiltered();
    });
  }, [
    activeFilters,
    currentUserId,
    parameters.deleted,
    parameters.sort,
    processes,
    searchString,
    sites,
    timezone,
    levels,
    forms,
    companies,
    contracts,
    loadingFormsStore,
    timezone,
  ]);

  const isLoading =
    loading ||
    loadingDeleted ||
    loadingSites ||
    loadingCompanies ||
    loadingContracts ||
    loadingLevels ||
    loadingForms;

  useEffect(() => {
    setCount(issuesOnView.total);
  }, [issuesOnView, setCount]);

  useEffect(() => {
    setLoadingIssueCount(isLoading);
  }, [isLoading, setLoadingIssueCount]);

  const ctx: IssuesContextType = {
    issues: issuesOnView,
    loading: isLoading,
    noIssuesReason: emptyListReason,
    setParameters,
    setColumns,
    getAllFilteredIssues,
    issuesAsModels: issues.items,
    issueModelsLoading: isLoading,
  };

  return (
    <IssuesContext.Provider value={ctx}>{children}</IssuesContext.Provider>
  );
};

export function useIssuesContext(): IssuesContextType {
  const context = useContext(IssuesContext);
  if (context === undefined) {
    throw new Error(
      'useIssuesContext must be used within an IssuesContextProvider'
    );
  }
  return context;
}

export const WithIssues = withIssueCount(_WithIssues);
