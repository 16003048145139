import { AxiosPromise } from 'axios';
import { BroadcastChannel } from 'broadcast-channel';
import { DetectedResolvedDto } from 'charts/detectedResolved/types';
import { IssueOnSingleView } from 'components/dataProviders/withIssue/model';
import { Store } from 'redux';
import { initialState } from 'redux/reducers/projectDataReducer';
import { logError } from 'setup/logger/logError';
import { broadcastCurrentUserPermissions } from 'shared/broadcastUserData';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { InspectionTemplateStatus } from 'shared/domain/template/templateStatus';
import { IssueEventInDto, IssueInDto } from 'shared/dtos/in/issue';
import {
  ChecklistItemInDto,
  InspectionTemplateInDto,
} from 'shared/dtos/in/template';
import { IssueEventOutDto } from 'shared/dtos/out/issue';
import {
  ChecklistItemOutDto,
  CreateChecklistItemsOutDto,
  InspectionTemplateOutDto,
} from 'shared/dtos/out/template';
import { setSelectionHappened } from 'views/projects/selection/model';
import { API } from './api';
import { AverageResolutionTimeDto } from './charts/resolutionTime/types';
import { parseEventDescription } from './client-helpers';
import { AppLocales } from './intl/IntlProviderWrapper';
import * as actions from './redux/actionTypes';
import {
  DocumentDescription,
  Issue,
  IssueProp,
  ProjectInfo,
  StoreState,
  UserUpdateDto,
} from './setup/types/core';
import { TOASTER_TYPES } from './shared/enums';
import { HashMap, Identificable } from './shared/types/commonView';
import { getSorter } from './views/issueTable/tableView/config';
import { UserWithPermissionsInDto } from 'shared/types/userRole';
import { deepEqual } from 'shared/utils/deepEqual';

type IssuePayload = {
  issues: Issue[];
  totalCount: number;
  hasMore: boolean;
  syncData: HashMap<string>;
  fetchedDeleted?: boolean;
  fetchedActive?: boolean;
};

export class Client {
  protected api: API;
  protected store: Store;
  private issueSync = Promise.resolve();
  private syncResolved = true;

  constructor(api: API, store: Store) {
    this.api = api;
    this.store = store;
    //in future we should consider fetching user/me from SW only
    this.listenToUserPermissionsChangeFromSW();
  }

  private listenToUserPermissionsChangeFromSW(): void {
    const broadcast = new BroadcastChannel(
      ChannelNames.currentUserChannel
    );

    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.currentUserPermissions) {
        if (event.data.userData as UserWithPermissionsInDto) {
          const state: StoreState = this.store.getState();
          const currentPermissions = state.user.data;
          if (!deepEqual(event.data.userData, currentPermissions)) {
            this.store.dispatch({
              type: actions.AUTH_SUCCESS,
              payload: event.data.userData,
            });
          }
        }
      }
    };
  }

  fetchMe(): Promise<void> {
    return this.api.fetchMe().then((response) => {
      this.store.dispatch({
        type: actions.AUTH_SUCCESS,
        payload: response.data,
      });
      broadcastCurrentUserPermissions(
        { userData: response.data },
        'apiClient: fetchMe'
      );
    });
  }

  authenticate(): Promise<void> {
    return this.fetchMe().catch((error) => {
      logError('authentication failed', error);

      this.store.dispatch({
        type: actions.AUTH_FAILURE,
        payload: error,
      });
    });
  }

  dispatchSelectedProjectInStore(
    projectData?: ProjectInfo
  ): Promise<void> {
    return Promise.resolve(projectData)
      .then((projectData) => {
        if (projectData) {
          this.store.dispatch({
            type: actions.FETCH_PROJECT_SUCCESS,
            payload: { ...projectData },
          });
          this.syncProjectData();
          setSelectionHappened();
        } else {
          this.store.dispatch({
            type: actions.FETCH_PROJECT_SUCCESS,
            payload: { ...initialState },
          });
        }
      })
      .catch((error) => {
        logError('sync selected project failed', error);

        this.store.dispatch({
          type: actions.FETCH_PROJECT_FAILURE,
          payload: { error },
        });
      });
  }

  syncProjectData(): Promise<void> {
    return Promise.resolve()
      .then(() => {
        const state: StoreState = this.store.getState();
        const projectId = state.projectData._id;

        if (!projectId) {
          return;
        }
        this.syncProcesses();
        this.syncIcons();
      })
      .catch((error) => {
        logError('sync selected project failed', error);

        this.store.dispatch({
          type: actions.FETCH_PROJECT_FAILURE,
          payload: { error },
        });
      });
  }

  setLanguage(locale: AppLocales): void {
    this.store.dispatch({
      type: actions.SET_LANGUAGE,
      payload: {
        locale,
      },
    });

    this.store.dispatch({ type: actions.CLEAR_SYNC_DATA });
    this.store.dispatch({ type: actions.CLEAR_ISSUE_LIST });

    this.syncProjectData();
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
    broadcast.postMessage({
      service: Services.LANGUAGE,
      method: ServiceMethods.CHANGE,
    });
    broadcast.close();
  }

  syncProcesses(): Promise<void> {
    const state: StoreState = this.store.getState();
    const projectData = state.projectData;

    return this.api
      .fetchProcesses(projectData._id)
      .then((processList) => {
        this.store.dispatch({
          type: actions.FETCH_PROCESS_LIST_SUCCESS,
          payload: { processes: processList },
        });
      })
      .catch((error) => {
        logError('fetching processes failed', error);

        this.store.dispatch({
          type: actions.FETCH_PROCESS_LIST_FAILURE,
          payload: { error },
        });
      });
  }

  syncIcons(): Promise<void> {
    return this.api
      .fetchIcons()
      .then((response) => {
        this.store.dispatch({
          type: actions.FETCH_ICONS_SUCCESS,
          payload: { icons: response.data },
        });
      })
      .catch((error) => {
        logError('fetching icons failed', error);

        this.store.dispatch({
          type: actions.FETCH_ICONS_FAILURE,
          payload: { error },
        });
      });
  }

  async syncAllIssues(args?: {
    type: 'FETCH_ISSUES_ALL' | 'FETCH_ISSUES_DELETED';
    fetchDeleted?: boolean;
    startFrom?: number;
  }): Promise<void> {
    if (!this.syncResolved) {
      return this.issueSync;
    }
    this.syncResolved = false;
    this.issueSync = this.theSync(args);
    return this.issueSync.then((res) => {
      this.syncResolved = true;
      return res;
    });
  }

  removeIssue(
    issue: Pick<
      IssueOnSingleView,
      '_id' | 'process' | 'createdAt' | 'ncrNumber'
    >
  ): Promise<boolean> {
    this.store.dispatch({ type: actions.ISSUE_DELETE_START });

    return this.api
      .removeIssue(issue._id)
      .then(() => {
        this.store.dispatch({
          type: actions.ISSUE_DELETE_SUCCESS,
          issueId: issue._id,
          meta: {
            type: TOASTER_TYPES.SUCCESS,
            toasterPosition: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            message: {
              id: 'issue_notification_delete_success',
              content: issue.ncrNumber,
            },
          },
        });

        return true;
      })
      .catch((error) => {
        logError('Error occurred when restoring issue', error);

        this.store.dispatch({
          type: actions.ISSUE_RESTORE_FAILURE,
          error,
        });

        return false;
      });
  }

  restoreIssue(
    issue: Pick<
      IssueOnSingleView,
      '_id' | 'createdAt' | 'process' | 'ncrNumber'
    >
  ): Promise<IssueInDto> {
    return this.api.restoreIssue(issue._id).then((response) => {
      this.store.dispatch({
        type: actions.ISSUE_RESTORE_SUCCESS,
        payload: {
          issueId: issue._id,
        },
        meta: {
          type: TOASTER_TYPES.SUCCESS,
          toasterPosition: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          message: {
            id: 'issue_notification_restore_success',
            content: issue.ncrNumber,
          },
        },
      });

      return response.data;
    });
  }

  updateDocumentDescription(
    issue: Identificable,
    eventId: string | undefined,
    documentId: string,
    description: string | null
  ): Promise<void> {
    return this.api
      .updateDocument(issue._id, eventId, documentId, {
        description,
      })
      .then((response) => {
        this.store.dispatch({
          type: actions.DOCUMENT_UPDATE_SUCCESS,
          payload: {
            issueId: issue._id,
            eventId,
            document: response.data.document,
          },
        });
      })
      .catch((error) => {
        logError('Error occurred when updating document', error);

        this.store.dispatch({
          type: actions.DOCUMENT_UPDATE_FAILURE,
          error,
        });
        throw error;
      });
  }

  createEvent(
    issue: Identificable,
    title: string,
    description: string | undefined
  ): Promise<IssueEventInDto> {
    const issueId = issue._id;

    return this.api
      .createEvent(
        issueId,
        title,
        parseEventDescription(description || '')
      )
      .then((response) => {
        const event = response.data;
        this.store.dispatch({
          type: actions.LOCK_EVENT_FOR_EDITING,
          payload: { eventId: event._id },
        });

        this.store.dispatch({
          type: actions.EVENT_CREATE,
          payload: { issueId: issueId, event },
        });

        return event;
      });
  }

  updateEvent(
    issue: Identificable,
    event: Identificable & { title: string },
    updatedValues: Partial<{
      documentsDescriptions: DocumentDescription;
      title: string;
      description: string;
    }>
  ): Promise<IssueEventOutDto> {
    const title: string = updatedValues.title ?? '';
    const description = parseEventDescription(
      updatedValues.description ?? ''
    );

    return this.api
      .updateEvent(issue._id, event._id, {
        title,
        description,
      })
      .then(async (response) => {
        this.store.dispatch({
          type: actions.EVENT_EDIT_SUCCESS,
          payload: {
            issueId: issue._id,
            event: response.data,
          },
        });

        if (
          updatedValues.documentsDescriptions &&
          Object.keys(updatedValues.documentsDescriptions).length
        ) {
          for (const documentId in updatedValues.documentsDescriptions) {
            await this.updateDocumentDescription(
              issue,
              event._id,
              documentId,
              updatedValues.documentsDescriptions[documentId]
            );
          }
        }
        return response.data;
      })
      .catch((error) => {
        logError('Error occurred when editing event', error);

        this.store.dispatch({
          type: actions.EVENT_EDIT_FAILURE,
          payload: { error },
        });
        throw error;
      });
  }

  deleteEvent(
    issue: Identificable,
    event: Identificable & { title: string },
    withToaster = true
  ): Promise<void> {
    return this.api
      .deleteEvent(issue._id, event._id)
      .then(() => {
        this.store.dispatch({
          type: actions.EVENT_DELETE_SUCCESS,
          payload: { issueId: issue._id, eventId: event._id },
          ...(withToaster && {
            meta: {
              type: TOASTER_TYPES.SUCCESS,
              message: {
                id: 'issue_events_notification_delete_success',
                content: event.title,
              },
            },
          }),
        });
      })
      .catch((error) => {
        logError('Error occurred when deleting event', error);

        this.store.dispatch({
          type: actions.EVENT_DELETE_FAILURE,
          payload: { error },
        });
      });
  }

  updateUser(body: UserUpdateDto): Promise<void> {
    return this.api
      .updateUser(body)
      .then((response) => {
        this.store.dispatch({
          type: actions.MODIFY_USER_SUCCESS,
          payload: { ...response.data },
        });
      })
      .catch((error) => {
        logError('Error occurred when modifying user', error);

        this.store.dispatch({
          type: actions.MODIFY_USER_FAILURE,
          payload: error,
          meta: {
            type: TOASTER_TYPES.FAILURE,
            message: { id: 'toaster_something_went_wrong' },
          },
        });
      });
  }

  createTemplate(
    body: InspectionTemplateOutDto
  ): AxiosPromise<InspectionTemplateInDto> {
    return this.api.createTemplate(body);
  }

  fetchTemplate(
    templateId: string
  ): AxiosPromise<InspectionTemplateInDto> {
    return this.api.fetchTemplate(templateId);
  }

  updateTemplate(
    templateId: string,
    body: InspectionTemplateOutDto
  ): AxiosPromise<InspectionTemplateInDto> {
    return this.api.updateTemplate(templateId, body);
  }

  createChecklistItems(
    templateId: string,
    body: CreateChecklistItemsOutDto
  ): AxiosPromise<ChecklistItemInDto[]> {
    return this.api.createChecklistItems(templateId, body);
  }

  updateChecklistItem(
    templateId: string,
    checklistItemId: string,
    body: ChecklistItemOutDto
  ): AxiosPromise<ChecklistItemInDto> {
    return this.api.updateChecklistItem(templateId, checklistItemId, body);
  }

  deleteChecklistItem(
    templateId: string,
    checklistItemId: string
  ): AxiosPromise<ChecklistItemInDto> {
    return this.api.deleteChecklistItem(templateId, checklistItemId);
  }

  publishTemplate(
    templateId: string
  ): AxiosPromise<InspectionTemplateInDto> {
    return this.api.updateTemplate(templateId, {
      status: InspectionTemplateStatus.published,
    });
  }

  removeTemplate(templateId: string): AxiosPromise<any> {
    return this.api.removeTemplate(templateId);
  }

  fetchResolvedDetected(
    urlParams: string
  ): AxiosPromise<DetectedResolvedDto> {
    const urlFromParams = `statistic/issuesDetectedResolved?${urlParams}`;
    return this.api.rawGetFromUrl(urlFromParams);
  }

  fetchOriginatorScore(urlParams: string): AxiosPromise<any> {
    const urlFromParams = `statistic/companyScore?${urlParams}`;
    return this.api.rawGetFromUrl(urlFromParams);
  }

  fetchResolutionTime(
    urlParams: string
  ): AxiosPromise<AverageResolutionTimeDto> {
    const urlFromParams = `statistic/issueAverageResolutionTime?${urlParams}`;
    return this.api.rawGetFromUrl(urlFromParams);
  }

  private async theSync(args?: {
    type: 'FETCH_ISSUES_ALL' | 'FETCH_ISSUES_DELETED';
    fetchDeleted?: boolean;
    startFrom?: number;
  }): Promise<void> {
    const { issue, projectData } = this.store.getState() as StoreState;
    if (issue.loading) {
      return;
    }
    const [key, direction] = getSorter();
    const projectId = projectData._id;

    const fetchDeleted = !!args?.fetchDeleted;
    const pageSize = 100;
    let moreIssues: boolean = true;
    let offset = issueOffset(args?.startFrom, issue, fetchDeleted);

    if (
      !issue.fetchedActive ||
      (args?.type === 'FETCH_ISSUES_DELETED' && !fetchDeleted)
    ) {
      this.store.dispatch({
        type: actions.FETCH_ISSUES_BEGIN,
      });
    }

    while (moreIssues) {
      const response = await this.api.fetchIssuesWithOffset({
        projectId,
        sort: key,
        direction,
        offset,
        size: pageSize,
        deleted: fetchDeleted,
      });

      const issues = response.data.issues;
      const syncKey = response.data.syncKey;

      moreIssues = response.data.hasMore;
      offset += pageSize;

      const payload: IssuePayload = {
        issues,
        totalCount: response.data.totalCount,
        hasMore: response.data.hasMore,
        syncData: {
          [projectId]: syncKey,
        },
      };
      if (fetchDeleted) {
        payload.fetchedDeleted = true;
      } else {
        payload.fetchedActive = true;
      }

      this.store.dispatch({
        type: actions.FETCH_ISSUES_SUCCESS,
        payload: payload,
      });
    }

    this.store.dispatch({
      type: actions.FETCH_ISSUES_FINISH,
    });
  }
}

function toChunks<T>(chunkSize: number, array: T[]): Array<T[]> {
  const result: any[] = [];
  for (let i = 0; i < array.length; i += chunkSize)
    result.push(array.slice(i, i + chunkSize));
  return result;
}

function fetchedAllDeleted(issueState: IssueProp): boolean {
  return (
    issueState.list.length - issueState.activeCount >=
    issueState.deletedCount
  );
}

function fetchedAllActive(issueState: IssueProp): boolean {
  return (
    issueState.list.length - issueState.deletedCount >=
    issueState.activeCount
  );
}

function getDeletedOffset(issueState: IssueProp): number {
  return fetchedAllDeleted(issueState)
    ? issueState.deletedCount
    : Math.max(0, issueState.list.length - issueState.activeCount);
}

function getActiveOffset(issueState: IssueProp): number {
  return fetchedAllActive(issueState)
    ? issueState.activeCount
    : Math.max(0, issueState.list.length - issueState.deletedCount);
}

function issueOffset(
  startFrom: number | undefined,
  issueState: IssueProp,
  isFetchingDeleted: boolean
): number {
  if (typeof startFrom === 'number') {
    return startFrom;
  }
  if (!isFetchingDeleted) {
    return getActiveOffset(issueState);
  }
  return getDeletedOffset(issueState);
}
